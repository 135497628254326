import React from 'react'
import { FaInstagram} from 'react-icons/fa';
// import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer className="bg-gray-900 text-gray-400 py-10 px-5 md:px-20" id='footer'>
      <div className="flex flex-col md:flex-row justify-between items-star space-y-6 md:space-y-0">
        
        {/* Left Section */}
        <div className="md:w-1/3">
    <div className='inline-block'>
        {/* <Link to="/admin"> */}
            <img className="logo" src="image/logo.png" alt="Logo" />
        {/* </Link> */}
    </div>
</div>
        
        {/* Middle Section */}
        <div className="md:w-1/3 flex flex-col">
        <div className="flex space-x-6 mt-4 md:mt-0">
          <p className='font-bold text-2xl'>Contact Us :-</p>
          <p className='font-bold text-2xl'>9990190505</p>
        </div>
        </div>
        
        {/* Right Section */}
        <div className="md:w-1/3 flex flex-col items-center md:items-end">
  <div className="flex flex-col items-center">
    <p className="text-white mb-2">Follow Us On</p>
    <a href="https://www.instagram.com/estatexlandbase?igsh=Zm53ejUyZjZ1c3N6" alt="insta" className='mt-2'>
      <FaInstagram className="text-2xl hover:text-pink-400 transition-colors cursor-pointer text-white" />
    </a>
  </div>
</div>
      </div>

      {/* Bottom Section */}
      <div className="mt-10 border-t border-gray-700 pt-6 text-sm flex flex-col md:flex-row justify-between items-center">
        <p>Marketed By - Authorised Channel Partner | Haryana RERA Regd NO. RC/ REA/HARERA/GGM|2019|279 This is not the official website of 
          developer property, it belongs to authorised channel partners for information purposes only. All rights for logo & images are reserved 
          to the developer. Thank you for visiting our website. This disclaimer ("Disclaimer") is applicable to this website and all microsites 
          and websites owned by us. By using or accessing this website you agree with the Disclaimer without any qualification or limitation.
          © 2024 Smart World. A luxury property of the highest standards being developed in Gurgaon </p>
      </div>
    </footer>
  )
}
