import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function Admin() {

  const [product, setProduct] = useState([]);

  function fetchData() {
    axios
      .get("https://smartworld-backend.onrender.com/fetchDetail")
      .then((res) => {
        console.log(res.data);
        setProduct(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container mx-auto mt-10">
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow-md sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Mobile</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {product.map((users) => (
                    <tr key={users._id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{users.name}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{users.mobile}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{users.description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
