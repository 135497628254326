import React, { useState } from 'react'
import PopForm from './PopForm';

export default function Location() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
    <div className="bg-black text-white py-10 px-5 md:px-20">
  <div className="flex flex-col lg:flex-row items-center justify-center lg:space-x-4">
    <div className="lg:text-left">
      <h2 className="text-4xl font-semibold mb-4">Location</h2>
      <ul className="text-lg space-y-3">
        <li>• Location: Gurgaon</li>
        <li>• Land area: 14 acres</li>
        <li>• Number of towers: 6 (3 twin towers)</li>
        <li>• Building height: 45 storeys</li>
        <li>• Design inspired by: Marina Bay Sands, Singapore</li>
      </ul>
      <button className="mt-6 bg-white text-black px-6 py-3 rounded-lg font-medium hover:bg-gray-300 transition-colors" onClick={handleOpenModal}>
        Get Details →
      </button>
    </div>
    <div className="w-full lg:w-1/2 max-w-lg mt-6 lg:mt-0">
      <img src="image/location.jpg" alt="Location Map" className="w-full h-auto" />
    </div>
  </div>
</div>
<PopForm
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title="Get Details"
      />
      </>
  )
}
