import React from 'react'

export default function ImageGrid() {
  return (
    <div className=" mx-auto p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="aspect-w-4 aspect-h-3">
          <img src="image/grid1.jpg" alt="Amenity 1" className="w-full h-full object-cover rounded-lg" />
        </div>
        <div className="aspect-w-4 aspect-h-3">
          <img src="image/grid2.jpg" alt="Amenity 2" className="w-full h-full object-cover rounded-lg" />
        </div>
        <div className="aspect-w-4 aspect-h-3">
          <img src="image/grid3.jpg" alt="Amenity 3" className="w-full h-full object-cover rounded-lg" />
        </div>
        <div className="aspect-w-4 aspect-h-3">
          <img src="image/grid4.jpg" alt="Amenity 4" className="w-full h-full object-cover rounded-lg" />
        </div>
        <div className="aspect-w-4 aspect-h-3">
          <img src="image/grid5.jpg" alt="Amenity 5" className="w-full h-full object-cover rounded-lg" />
        </div>
        <div className="aspect-w-4 aspect-h-3">
          <img src="image/grid6.jpg" alt="Amenity 6" className="w-full h-full object-cover rounded-lg" />
        </div>
      </div>
    </div>
  )
}
