import React from 'react';
import { FaDumbbell } from 'react-icons/fa';
import { MdSecurity } from 'react-icons/md';
import { FaSwimmingPool } from 'react-icons/fa';
import { FaTree } from 'react-icons/fa';
import { GiDiamondHard } from 'react-icons/gi';
import { FaChild } from 'react-icons/fa';
import { FaSpa } from 'react-icons/fa';
import { FaBuilding } from 'react-icons/fa';


export default function Aminities() {
  return (
    <div className="max-w-screen-lg mx-auto p-4" id='aminities'>
      <h2 className="text-2xl font-bold text-center mb-8">Luxury Amenities</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <div className="flex flex-col items-center bg-blue-100 p-6 rounded-lg shadow-md">
          <FaDumbbell className="text-4xl text-blue-600 mb-4" />
          <h3 className="text-lg font-semibold text-blue-600 text-center">Equipped Gym, Spa & Salon</h3>
        </div>
        <div className="flex flex-col items-center bg-blue-600 text-white p-6 rounded-lg shadow-md">
          <MdSecurity className="text-4xl mb-4" />
          <h3 className="text-lg font-semibold text-center">Gated Secured Community</h3>
        </div>
        <div className="flex flex-col items-center bg-blue-600 text-white p-6 rounded-lg shadow-md">
          <FaBuilding className="text-4xl mb-4" />
          <h3 className="text-lg font-semibold text-center">Luxury Club House</h3>
        </div>
        <div className="flex flex-col items-center bg-blue-100 p-6 rounded-lg shadow-md">
          <FaSwimmingPool className="text-4xl text-blue-600 mb-4" />
          <h3 className="text-lg font-semibold text-blue-600 text-center">Swimming Pool</h3>
        </div>
        <div className="flex flex-col items-center bg-blue-100 p-6 rounded-lg shadow-md">
          <FaSpa className="text-4xl text-blue-600 mb-4" />
          <h3 className="text-lg font-semibold text-blue-600 text-center">Double Height Managed Lobbies</h3>
        </div>
        <div className="flex flex-col items-center bg-blue-600 text-white p-6 rounded-lg shadow-md">
          <GiDiamondHard className="text-4xl mb-4" />
          <h3 className="text-lg font-semibold text-center">Professional Maintenance</h3>
        </div>
        <div className="flex flex-col items-center bg-blue-100 p-6 rounded-lg shadow-md">
          <FaTree className="text-4xl text-blue-600 mb-4" />
          <h3 className="text-lg font-semibold text-blue-600 text-center">Landscaped Greens</h3>
        </div>
        <div className="flex flex-col items-center bg-blue-600 text-white p-6 rounded-lg shadow-md">
          <FaChild className="text-4xl mb-4" />
          <h3 className="text-lg font-semibold text-center">Kids Play Area</h3>
        </div>
      </div>
    </div>
  )
}
