import React from 'react'

export default function InfoSection() {
  return (
    <div className="bg-black text-white text-center flex flex-col md:flex-row justify-around items-center py-8 px-4 md:px-16 w-full" id='infoSection'>
      {/* Left Section */}
      <div className="mb-8 md:mb-0">
        <h2 className="text-4xl font-bold">
          3.5 & 4.5 BHK
          <br />
          <span className="text-lg font-medium text-red-700">APARTMENT</span>
        </h2>
        <hr className="w-16 my-4 mx-auto border-yellow-700" />
        <h3 className="text-3xl font-bold">
          ₹ 3.75 CR*
          <br />
          <span className="text-lg font-medium text-red-700">ONWARDS</span>
        </h3>
      </div>

      {/* Middle Section */}
      <div className="mb-8 md:mb-0">
        <h2 className="text-lg font-medium text-red-700">Replica of</h2>
        <h1 className="text-3xl md:text-4xl font-bold">Marina Bay Sands</h1>
        <p className="text-lg mt-2">Coming Soon..</p>
      </div>

      {/* Right Section */}
      <div>
        <h2 className="text-lg font-medium text-red-700">PRE BOOKING</h2>
        <h1 className="text-3xl md:text-4xl font-bold">OPEN NOW</h1>
      </div>
    </div>
  )
}
