import axios from 'axios';
import React, { useState } from 'react'

export default function Home() {
  const[user,setUser]=useState("");

    const inputHandler=(e)=>{
      return setUser({...user,[e.target.name]:e.target.value});
    }

    const dataSubmit=(e)=>{
      e.preventDefault();
      axios.post("https://smartworld-backend.onrender.com/userEnquiry",user)
      .then(res=>{
        alert(res.data.msg);
        setUser({
          name: '',
          mobile: '',
          description: ''
        });
      }).catch(err=>{alert(err.response.data.error)});
    }
  return (
    <>
    <div
      className="relative h-screen w-full bg-cover bg-center flex items-center justify-center md:justify-between"
      style={{
        backgroundImage: "url('image/city.jpg')",
      }}
    >
      {/* Text Section */}
      <div className="absolute left-4 md:left-10 top-1/3 bg-gray-800 bg-opacity-50 text-white p-4 md:p-6 rounded-md max-w-xs md:max-w-md">
        <h1 className="text-3xl md:text-4xl font-bold">Smartworld 69</h1>
        <p className="mt-2 text-lg md:text-xl">Sector 69, Gurugram</p>
      </div>

      {/* Form Section */}
      <div className="absolute right-4 md:right-10 top-1/4 bg-gray-900 bg-opacity-50 p-6 md:p-8 rounded-md shadow-lg w-11/12 max-w-sm md:max-w-md">
        <h2 className="text-white text-lg md:text-xl font-semibold mb-4">DROP AN ENQUIRY</h2>
        <form className="space-y-4" onSubmit={dataSubmit}>
          <input
            type="text"
            placeholder="Name*"
            className="w-full p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            name='name' 
            value={user?.name}
            onChange={inputHandler}
          />
          <input
            type="number"
            placeholder="Mobile No.*"
            className="w-full p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            name='mobile' 
            value={user?.mobile}
            onChange={inputHandler}
          />
          {/* <input
            type="email"
            placeholder="Email"
            className="w-full p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            name='email' 
            value={user?.email}
            onChange={inputHandler}
          /> */}
          <textarea
            placeholder="Your Message"
            className="w-full p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-24"
            name='description' 
            value={user?.description}
            onChange={inputHandler}
          ></textarea>
          <button
            type="submit"
            className="w-full bg-black text-white p-3 rounded-md hover:bg-gray-800"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  </>
  )
}
