import React, { useState } from "react";
import './Header.css';
import { GiHamburgerMenu } from 'react-icons/gi';
import { FaPhoneVolume } from "react-icons/fa";


export default function Header() {
    const [showMediaIcons,setShowMediaIcons] = useState(false);

    return (
      <header className='header border-b-2 border-red-900 w-full'>
        
        <div className='web-name'>
        <div className='logo-div text-danger'>
        <a  className='text-decoration-none'><img className="logo m-1" src="image/logo.png" /></a> 
        </div>
        </div>
  
        <nav className= {showMediaIcons ? "mobile-navbar" : 'navbar'} >
          <ul className= {showMediaIcons ? "mobile-navbar-list" : 'navbar-list '}>
            <li><a href='#' className='navbar-link text-black no-underline'>Home</a></li>
            <li><a href="#infoSection" className='navbar-link text-black no-underline'>About</a></li>
            <li><a href="#aminities" className='navbar-link text-black no-underline'>Amenities</a></li>
            <li><a href="#priceList" className='navbar-link text-black no-underline'>Highlights</a></li>
            <li><a href="#footer" className='navbar-link text-black no-underline'>Contact</a></li>
          </ul>

        </nav>
        <a href="tel:+917042316503" className="text-3xl text-blue-500 jumping-icon">
          <FaPhoneVolume />
        </a>
  
        <div className='mobile-navbar-btn'>
          <a href='#' name='ham' className='mobile-nav-icon' onClick={() => setShowMediaIcons(!showMediaIcons)}>
            <GiHamburgerMenu/>
          </a>
        </div>
      </header>
    );
  }