import React, { useState } from 'react'
import PopForm from './PopForm';

export default function Section() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  
  return (
    <div className="bg-white py-12 px-4 flex flex-col md:flex-row items-center max-w-7xl mx-auto min-h-[70vh]">
      {/* Text Section */}
      <div className="md:w-1/2 text-left mr-5">
        <h1 className="text-3xl md:text-4xl font-bold text-blue-900">
          Smartworld 69
        </h1>
        <p className="mt-4 text-xl text-gray-600">
        The elegant 3.5/4.5 BHK apartments at Smart World 69, Sector 69 property, Gurgaon are waiting to be discovered, where the floor layouts range from 2200 to 2700 sq. ft; and space covers around a stretched area of approximately 7.50 acres with each of its six towers consisting of 630 units.
        </p>
        <p className="mt-4 text-xl text-gray-600">
        Smart world sector-69 offers easy access to the city without having to encounter its congestion and is well connected by road and rail networks besides being close to happening places such as pubs and clubs. This is the best location for you and your loved ones to enjoy life in an exclusive environment that combines luxury with comfort.”
        </p>
        <button
          className="mt-6 bg-black text-white px-6 py-3 rounded-md text-lg hover:bg-gray-800 transition duration-300"
          onClick={handleOpenModal}
        >
          Get Details →
        </button>
      </div>

      {/* Image Section */}
      <div className="mt-8 md:mt-0 md:w-1/2 relative">
        <img
          src="image/section2.jpg"
          alt="Smartworld 69"
          className="shadow-xl h-full object-cover rounded-sm border-3"
        />
      </div>

      {/* Modal with Form */}
      <PopForm
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title="Get Details"
      />
    </div>
  )
}
