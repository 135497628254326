import React, { useState } from 'react'
import PopForm from './PopForm';

export default function PriceList() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="max-w-screen-lg mx-auto p-4" id='priceList'>
      <h2 className="text-2xl font-bold text-center mb-8">Price List & Area</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr>
              <th className="px-6 py-4 text-left text-blue-600 font-semibold border-b">Area</th>
              <th className="px-6 py-4 text-left text-blue-600 font-semibold border-b">Type</th>
              <th className="px-6 py-4 text-left text-blue-600 font-semibold border-b">Offer Price</th>
              <th className="px-6 py-4 text-left text-blue-600 font-semibold border-b">Booking Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="px-6 py-4 border-b">2200-2400 sqft</td>
              <td className="px-6 py-4 border-b">3.5 BHK</td>
              <td className="px-6 py-4 border-b">
                <button className="px-4 py-2 text-blue-600 border border-blue-600 rounded" onClick={handleOpenModal}>Get Quote</button>
              </td>
              <td className="px-6 py-4 border-b">₹10 Lac
              </td>
            </tr>
            <tr>
              <td className="px-6 py-4 border-b">3000-3200 sqft</td>
              <td className="px-6 py-4 border-b">4.5 BHK</td>
              <td className="px-6 py-4 border-b">
                <button className="px-4 py-2 text-blue-600 border border-blue-600 rounded" onClick={handleOpenModal}>Get Quote</button>
              </td>
              <td className="px-6 py-4 border-b">₹15 Lac
              </td>
            </tr>
            <tr>
              <td className="px-6 py-4 border-b">6500-7000 sqft</td>
              <td className="px-6 py-4 border-b">Penthouse (4BHK)</td>
              <td className="px-6 py-4 border-b">
                <button className="px-4 py-2 text-blue-600 border border-blue-600 rounded" onClick={handleOpenModal}>Get Quote</button>
              </td>
              <td className="px-6 py-4 border-b">₹25 Lac</td>
            </tr>
          </tbody>
        </table>
        {/* Modal with Form */}
      <PopForm
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title="Get Details"
      />
      </div>
    </div>
  )
}
