import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import axios from 'axios';

const PopForm = ({ isOpen, onClose, title }) => {
    const [user, setUser] = useState({
        name: '',
        mobile: '',
        description: ''
      });
    
      if (!isOpen) return null;
    
      const handleSubmit = (e) => {
        e.preventDefault();
        axios.post("https://smartworld-backend.onrender.com/userEnquiry", user)
          .then(res => {
            alert(res.data.msg);
            setUser({ name: '', mobile: '', description: '' }); // Reset form fields
            onClose(); // Close the modal
          })
          .catch(err => {
            alert(err.response.data.error);
          });
      };
    
      const inputHandler=(e)=>{
        return setUser({...user,[e.target.name]:e.target.value});
      }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 relative w-full max-w-md">
        <button
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          <FaTimes className="text-2xl" />
        </button>
        {title && <h2 className="text-2xl font-bold mb-4">{title}</h2>}
        
        {/* Form Content */}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <input
            type="text"
            placeholder="Name*"
            className="w-full p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            name='name' 
            value={user?.name}
            onChange={inputHandler}
          />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Phone Number
            </label>
            <input
            type="number"
            placeholder="Mobile No.*"
            className="w-full p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            name='mobile' 
            value={user?.mobile}
            onChange={inputHandler}
          />
          </div>
          <textarea
            placeholder="Your Message"
            className="w-full p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-24"
            name='description' 
            value={user?.description}
            onChange={inputHandler}
          ></textarea>
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 transition duration-300"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default PopForm;
