import './App.css';
import Aminities from './Components/Aminities';
import Footer from './Components/Footer';
import Header from './Components/Header';
import Home from './Components/Home';
import ImageGrid from './Components/ImageGrid';
import InfoSection from './Components/InfoSection';
import Location from './Components/Location';
import PriceList from './Components/PriceList';
import Section from './Components/Section';
import WhatsAppIcon from './Components/WhatsAppIcon';

function App() {
  return (
    <div className="App">
       <Header/>
       <Home/>
       <InfoSection/>
       <Section/>
       <Aminities/>
       <ImageGrid/>
       <PriceList/>
       <Location/>
       <Footer/>
       <WhatsAppIcon/>
    </div>
  );
}

export default App;
